import { CommonServices } from './CommonServices';
import { SignalrServices } from './SignalrManager';
import * as OM from '@/Model';
import { HubConnectionState } from '@microsoft/signalr';

class _WSSServices {

    private connection: signalR.HubConnection;
    private isLock: boolean = false;
    private numberOfConnection = 0;

    connect(onNotificationReceived: (val: string) => void,
        onNewWssError: (error: string) => void,
        onChatCreated: () => void){

        //Se la connessione è già stata creata e non è in stato di disconnessione o disconnessionamento return
        if(this.isCreated() && !this.isDisconnected() && !this.isDisconnecting())
            return;

        //Se la connessione è già in fase di avvio o non è stata disconnessa return
        if(this.isLock)
            return;

        this.isLock = true;

        let params = { 
        };
        let eventListeners = {
            notificationReceived: (val: string) => {
                onNotificationReceived(val);
            }
        }
        
        this.connection = SignalrServices.connectToHub('hubs/chat', params, eventListeners, onChatCreated);
        this.connection.on(WssMethodNames.NewWssError, onNewWssError);
    }

    disconnect(){
        if(this.numberOfConnection != 0){
            setTimeout(() => {
                this.disconnect();
            }, 100); 
        } else {
            this._disconnect();
        }
    }
        
    private _disconnect(){
        SignalrServices.disconnect(this.connection);
        this.isLock = false;
    } 
    
    connectChat(chatIdentifier: string, 
        onMessageReceive: (val: string) => void,
        onTakeOffAffinity: () => void){
        
        if(!this.isConnected()){
            setTimeout(() => {
                this.connectChat(chatIdentifier, onMessageReceive, onTakeOffAffinity);
            }, 100); 
        } else {
            this._connectChat(chatIdentifier, onMessageReceive, onTakeOffAffinity);
        }
    }

    private _connectChat(chatIdentifier: string, 
        onMessageReceive: (val: string) => void,
        onTakeOffAffinity: () => void){

        this.connection.invoke('ConnectToChatroom', chatIdentifier)
        .then( () => {
            this.connection.on(WssMethodNames.MessageReceived, onMessageReceive);
            this.connection.on(WssMethodNames.TakeOffAffinity, onTakeOffAffinity);
            this.numberOfConnection ++;
        })
    }

    disconnectChat(chatIdentifier: string, 
        onMessageReceive: (val: string) => void,
        onTakeOffAffinity: () => void){

        this.connection.invoke('DisconnectFromChatroom', chatIdentifier)
        .then( () => {
            this.connection.off(WssMethodNames.MessageReceived, onMessageReceive);
            this.connection.off(WssMethodNames.TakeOffAffinity, onTakeOffAffinity);
            this.numberOfConnection--;
        })
    }

    connectAffinity(onMessageReceive: (val: string) => void,
        onTakeOffAffinity: (chatRoomIdentifier: string) => void,
        onNewAffinity: (val: string) => void){

        if(!this.isConnected()){
            setTimeout(() => {
                this.connectAffinity(onMessageReceive, onTakeOffAffinity, onNewAffinity);
            }, 100); 
        } else {
            this._connectAffinity(onMessageReceive, onTakeOffAffinity, onNewAffinity);
        }
    }
    
    private _connectAffinity(onMessageReceive: (val: string) => void,
        onTakeOffAffinity: (chatRoomIdentifier: string) => void,
        onNewAffinity: (val: string) => void){

        this.connection.invoke('ConnectToAffinitySections')
        .then( () => {
            this.connection.on(WssMethodNames.MessageReceived, onMessageReceive);
            this.connection.on(WssMethodNames.TakeOffAffinity, onTakeOffAffinity);
            this.connection.on(WssMethodNames.NewAffinity, onNewAffinity);
            this.numberOfConnection++;
        })
    }

    disconnectAffinity(onMessageReceive: (val: string) => void,
        onTakeOffAffinity: (chatRoomIdentifier: string) => void,
        onNewAffinity: (val: string) => void){

        this.connection.invoke('DisconnectFromAffinitySections')
        .then( () => {
            this.connection.off(WssMethodNames.MessageReceived, onMessageReceive);
            this.connection.off(WssMethodNames.TakeOffAffinity, onTakeOffAffinity);
            this.connection.off(WssMethodNames.NewAffinity, onNewAffinity);
            this.numberOfConnection--;
        })
    }

    connectToNotificationSections(onNotificationReceivedInNotificationsSection: (val: string) => void){
        if(!this.isConnected()){
            setTimeout(() => {
                this.connectToNotificationSections(onNotificationReceivedInNotificationsSection);
            }, 100); 
        } else {
            this._connectToNotificationSections(onNotificationReceivedInNotificationsSection);
        }
    }
    
    private _connectToNotificationSections(onNotificationReceivedInNotificationsSection: (val: string) => void){
        //Se la connessione è già stata creata e non è in stato di disconnessione o disconnessionamento return
        this.connection.invoke('ConnectToNotificationSections')
        .then( () => {
            this.connection.on(WssMethodNames.NotificationReceivedInNotificationsSection, onNotificationReceivedInNotificationsSection);
            this.numberOfConnection++;
        })
    }

    disconnectFromNotificationSections(onNotificationReceivedInNotificationsSection: (val: string) => void){
        this.connection.invoke('DisconnectFromNotificationSections')
        .then( () => {
            this.connection.off(WssMethodNames.NotificationReceivedInNotificationsSection, onNotificationReceivedInNotificationsSection);
            this.numberOfConnection--;
        })
    }

    sendMessage(chatIdentifier: string, message: OM.ChatMessageVM){
        try {
            var messageStringified = JSON.stringify(message);
            return this.connection.invoke('NewMessage', chatIdentifier, messageStringified)
        } catch(ex){
            console.log(ex)
        }
    }

    takeOffAffinity(chatIdentifier: string){
        return this.connection.invoke(WssMethodNames.TakeOffAffinity, chatIdentifier)
    }

    takeOffMultipleAffinity(chatIdentifiers: string[]){
        return this.connection.invoke('TakeOffMultipleAffinity', chatIdentifiers)
    }

    isCreated(){
        return this.connection;
    }

    isConnected(){
        return this.connection.state == HubConnectionState.Connected;
    }

    isConnecting(){
        return this.connection.state == HubConnectionState.Connecting;
    }

    isDisconnected(){
        return this.connection.state == HubConnectionState.Disconnected;
    }

    isDisconnecting(){
        return this.connection.state == HubConnectionState.Disconnecting;
    }

    isReconnecting(){
        return this.connection.state == HubConnectionState.Reconnecting;
    }

}

var WssMethodNames = {
    MessageReceived: "messageReceived",
    TakeOffAffinity: "takeOffAffinity",
    NotificationReceived: "notificationReceived",
    NotificationReceivedInNotificationsSection: "notificationReceivedInNotificationsSection",
    NewAffinity: "newAffinity",
    NewWssError: "newWssError"
}

export let WSSServices = new _WSSServices();