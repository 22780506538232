import { CommonServices } from './CommonServices';
import * as OM from '@/Model';
import * as signalR from "@microsoft/signalr";
import { baseUrl } from '@/config';
import { StorageServices } from './StorageServices';
import store from '@/store';
import { AppUserClient } from './Services';
import { LoginServices } from './LoginServices';
import { getViewWithServices } from '@/utils';

class _SignalrServices {
    
    connectToHub(hubPath: string, params: { [key: string]: any }, eventsListener: { [key: string]: (...args: any[]) => void }, onChatCreated: () => void){
        let queryParams = Object.keys(params).length > 0 ? '?' : '';
        
        var prefix = "";

        for(let prop in params){
            queryParams += prefix + prop + '=' + params[prop];
            prefix = "&";
        }

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(baseUrl + hubPath + queryParams, {
                withCredentials: false,
                accessTokenFactory: () => {
                    return store.state.loggedUser.token;
                } 
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();

        for(let prop in eventsListener){
            connection.on(prop, eventsListener[prop]);
        }
        
        connection.onclose(() => {
            this.startConnection(connection);
        });

        this.startConnection(connection, onChatCreated);

        return connection;
    }

    private startConnection(connection: signalR.HubConnection, onChatCreated: () => void = null) {
        connection.start()
        .then( () => {
            if(onChatCreated)
                onChatCreated();
            // console.log("SignalR Connected.");
        })
        .catch( async err => {
            // refreshTonken
            // console.log("NUOVA CONNESSIONE", err)

            // var errorMessage = err.toString();  
            // if(errorMessage.indexOf("499") != -1){
            //     var loggedUser = store.state.loggedUser;
            //     var tokens: OM.AuthResponse = {
            //         refreshToken: loggedUser.refreshToken,
            //         token: loggedUser.token,
            //         refreshTokenExpiryTime: ""
            //     };

            //     await AppUserClient.refreshToken(tokens)
            //     .then(refreshTokenResponse => {
            //         var token = refreshTokenResponse.token;
                    
            //         StorageServices.setNewTokensByAuthResponse(refreshTokenResponse);
            //     }).catch( err => {
            //         LoginServices.logout();
                    
            //         var data = {
            //             Message: getViewWithServices().$localizationService.getLocalizedValue("app_LaTuaSessioneEScaduta", "La tua sessione è scaduta! <br />  Effettua di nuovo l'accesso."),
            //         };
            //         return Promise.reject(data);
            //     })
            // }
            
            // if(errorMessage.indexOf("401") != -1)
            //     return;

            //copia le connection
            // let newConnection = this.connectToHub("hubs/chat", {}, {}, () => {});

            // const eventNames = Object.keys(connection);

            // for (const eventName of eventNames) {
            //     if (eventName.startsWith("on") && typeof connection[eventName] === "function") {
            //         const eventHandler = connection[eventName];
            //         newConnection.on(eventName.substring(2), eventHandler);
            //     }
            // }

            // connection = newConnection;

            setTimeout(() => this.startConnection(connection, onChatCreated), 2000);
        })
    };

    disconnect(connection: signalR.HubConnection){
        (<any[]>(<any>connection)._closedCallbacks).splice(0);
        connection.stop();
    }

}

export let SignalrServices = new _SignalrServices();